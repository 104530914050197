import en from "./en.json";
import sp from "./sp.json";
import fr from "./fr.json";
import po from "./po.json";
import du from "./du.json";

export const dictionaryList = { en, sp, fr, po, du };

export const languageOptions = {
  en: "English",
  sp: "Spanish",
  fr: "French",
  po: "Portugeese",
  du: "Dutch",
};
