import ActionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  customerStoryData: [],
  error: {},
};

const customerStoryreducer = (state = { INITIAL_STATE }, action = {}) => {
  switch (action.type) {
    case ActionTypes.GetCustomerStoryList:
      return {
        ...state,
        customerStoryData: action.payload,
      };
    default:
      return state;
  }
};

export default customerStoryreducer;
