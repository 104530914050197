import ActionTypes from "./actionTypes";
import axios from "axios";
import * as config from "../../static/constants";

export const GetUserList =
  (accessToken, includeBlocked) => async (dispatch) => {
    try {
      const header = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      let res = await axios.post(
        `${config.BACKEND_API_URL}user/get-all`,
        { includeBlocked },
        header
      );
      //console.log(res);
      if (res.status === 200) {
        dispatch({
          type: ActionTypes.GetUserList,
          payload: res.data.data.data,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: ActionTypes.GetUserListError,
        payload: err,
      });
    }
  };

// export const AddUser = async (accessToken, submitData) => {
//     try {

//         const header = {
//             headers: { Authorization: `Bearer ${accessToken}` }
//         };

//         let res = await axios.post(`${config.BACKEND_API_URL}news/add`, submitData, header);
//         //console.log(res);
//         if (res.status === 200) {
//             return true
//         }
//     } catch (err) {
//         console.log(err);
//     }

//     return false
// }

export const blockUser = async (accessToken, id) => {
  try {
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    let res = await axios.post(
      `${config.BACKEND_API_URL}user/block/${id}`,
      {},
      header
    );
    //console.log(res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const approveUser = async (accessToken, id, status) => {
  try {
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    let res = await axios.post(
      `${config.BACKEND_API_URL}user/update/${id}`,
      { status },
      header
    );
    //console.log(res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const userRoleChange = async (accessToken, id, role) => {
  try {
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    let res = await axios.post(
      `${config.BACKEND_API_URL}user/update/${id}`,
      { role },
      header
    );
    //console.log(res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const userIpAddressChange = async (accessToken, id, ip_address) => {
  try {
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    let res = await axios.post(
      `${config.BACKEND_API_URL}user/update/${id}`,
      { ip_address },
      header
    );
    //console.log(res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const removeUser = async (accessToken, id) => {
  try {
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    let res = await axios.post(
      `${config.BACKEND_API_URL}user/remove/${id}`,
      {},
      header
    );
    //console.log(res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const bulkDeleteUsers = async (accessToken, ids) => {
  try {
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    let res = await axios.post(
      `${config.BACKEND_API_URL}user/bulk-delete`,
      { ids },
      header
    );
    //console.log(res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const bulkVerifyUsers = async (accessToken, ids) => {
  try {
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    let res = await axios.post(
      `${config.BACKEND_API_URL}user/bulk-verify`,
      { ids },
      header
    );
    //console.log(res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const addUser = async (formData, accessToken) => {
  try {
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const data = Object.fromEntries(formData.entries());
    let res = await axios.post(
      `${config.BACKEND_API_URL}user/add`,
      data,
      header
    );
    //console.log(res);
    if (res.status === 200) {
      return true;
    }
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const confirmAddUser = (confirmMessage) => async (dispatch) => {
  dispatch({
    type: ActionTypes.ConfirmAddUser,
    payload: confirmMessage,
  });
};

// export const uploadNewsImage = async (accessToken, submitData, _id) => {
//     try {

//         const header = {
//             headers: { Authorization: `Bearer ${accessToken}` }
//         };

//         let res = await axios.post(`${config.BACKEND_API_URL}news/upload/${_id}`, submitData, header);
//         //console.log(res);
//         if (res.status === 200) {
//             return true
//         }
//     } catch (err) {
//         console.log(err);
//     }

//     return false
// }

// export const updateUser = async (accessToken, submitData, _id) => {
//     try {

//         const header = {
//             headers: { Authorization: `Bearer ${accessToken}` }
//         };

//         let res = await axios.post(`${config.BACKEND_API_URL}user/update/${_id}`, submitData, header);
//         //console.log(res);
//         if (res.status === 200) {
//             return true
//         }
//     } catch (err) {
//         console.log(err);
//     }

//     return false
// }
