import React, { useEffect, useState } from "react";

import swal from "sweetalert";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { addUser, confirmAddUser, GetUserList } from "redux/actions/users";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTableCell-root": {
      cursor: "pointer",
      color: "black",
      textAlign: "center",
    },
    "& .MuiPaper-root": {
      overflowX: "scroll",
      marginTop: "20px",
    },
    "& .MuiTableHead-root": {
      "& .MuiTableCell-root": {
        backgroundColor: "lightgray",
        fontWeight: "bold",
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root:nth-child(even)": {
        backgroundColor: "lightgray",
      },
      "& .MuiTableRow-root:hover": {
        backgroundColor: "#aaa !important",
      },
      "& .MuiTableRow-root:active": {
        backgroundColor: "#ddd !important",
      },
    },
    "& .MuiTablePagination-root": {
      "& .MuiTablePagination-selectLabel": {
        margin: "0px !important",
        fontWeight: "bold",
      },
      "& .MuiTablePagination-displayedRows": {
        margin: "0px !important",
        fontWeight: "bold",
      },
    },
  },
}));

const AddUser = (props) => {
  const {
    open,
    handleClose,
    confirmAddUser,
    message,
    GetUserList,
    accessToken,
  } = props;

  const classes = useStyles();

  const [name, setName] = useState("");
  const [email_address, setEmailAddress] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [wallet_address, setWalletAddress] = useState("");

  const validate_email_address = (email_address) => {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/;

    return email_address.match(mailformat);
  };

  const validate_wallet_address = (address) => {
    return /^(0x)[0-9a-fA-F]{40}$/.test(address);
  };

  useEffect(() => {
    if (message === "IDLE") return;

    if (message === "SUCCESS") {
      swal({
        title: "SUCCESS",
        text: "Add User Successfully!",
        icon: "success",
        timer: 2000,
        button: false,
      });
      handleClose();
    }
    if (message === "ERROR") {
      swal({
        title: "ERROR",
        text: props.error,
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
    confirmAddUser("IDLE");
  }, [message]);

  const onAddUser = async () => {
    if (
      name === "" ||
      email_address === "" ||
      phone_number === "" ||
      wallet_address === ""
    ) {
      swal({
        title: "Empty Error",
        text: "Please, Input all fields!!!",
        icon: "warning",
        timer: 2000,
        button: false,
      });
      return;
    }

    if (!validate_email_address(email_address)) {
      swal({
        title: "Email Warning",
        text: "Invalid Email Format!!!",
        icon: "warning",
        timer: 2000,
        button: false,
      });
      return;
    }
    if (!validate_wallet_address(wallet_address)) {
      swal({
        title: "Wallet Address Error",
        text: "Invalid Wallet Address Format!!!",
        icon: "warning",
        timer: 2000,
        button: false,
      });
      return;
    }

    const fn = new FormData();

    fn.append("name", name);
    fn.append("email", email_address);
    fn.append("phone_number", phone_number);
    fn.append("wallet_addr", wallet_address);

    const result = await addUser(fn, accessToken);
    result ? alert("OK") : alert("No");
    handleClose();
    await GetUserList(accessToken);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.root}
      >
        <DialogTitle>
          <Box
            component={"div"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              component={"span"}
              className="modal-title"
              style={{ width: "300px" }}
            >
              Add User
            </Box>
            <Box
              component={"button"}
              type="button"
              className="close"
              onClick={handleClose}
            >
              &times;
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box component={"div"} className="form-group">
              <Box component={"label"} htmlFor="name">
                Name
              </Box>
              <Box
                component={"input"}
                type="text"
                id="name"
                className="form-control"
                onChange={(e) => setName(e.target.value)}
                autoComplete={"off"}
              />
            </Box>
            <Box component={"div"} className="form-group">
              <Box component={"label"} htmlFor="email_address">
                Email address
              </Box>
              <Box
                component={"input"}
                type="text"
                id="emial_address"
                className="form-control"
                onChange={(e) => setEmailAddress(e.target.value)}
                autoComplete={"off"}
              />
            </Box>
            <Box component={"div"} className="form-group">
              <Box component={"label"} htmlFor="phone_number">
                Phone Number
              </Box>
              <Box
                component={"input"}
                type="text"
                id="phone_number"
                className="form-control"
                onChange={(e) => setPhoneNumber(e.target.value)}
                autoComplete={"off"}
              />
            </Box>
            <Box component={"div"} className="form-group">
              <Box component={"label"} htmlFor="wallet_address">
                Wallet address
              </Box>
              <Box
                component={"input"}
                type="text"
                id="wallet_address"
                className="form-control"
                onChange={(e) => setWalletAddress(e.target.value)}
                autoComplete={"off"}
              />
            </Box>
            {/* <Box component={"div"} className='form-group'>
                            <Box component={"label"} htmlFor="status" >Status</Box>
                            <Box component={"select"} fullWidth type="text" id="status" className='form-control' defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                                <Box component={"option"} value={`pending`} >Pending</Box>
                                <Box component={"option"} value={`approved`}>Approved</Box>
                                <Box component={"option"} value={`verifying`}>Verifying</Box>
                                <Box component={"option"} value={`verified`}>Verified</Box>
                                <Box component={"option"} value={`blocked`}>Blocked</Box>
                            </Box>
                        </Box> */}
          </Box>
          <Box
            component={"div"}
            className="modal-footer"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box
              component={"button"}
              className="btn btn-default"
              onClick={handleClose}
            >
              Cancel
            </Box>
            <Box
              component={"button"}
              className="btn btn-primary btn-md"
              onClick={() => onAddUser()}
            >
              Add
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddUser.propsType = {
  message: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  message: state.user ? state.user.message : "IDLE",
  error: state.user ? state.user.error : null,
});
const mapDispatchToProps = {
  confirmAddUser,
  GetUserList,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
